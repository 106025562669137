// import { configureStore } from "@reduxjs/toolkit";
// import reducer from "../reducer";

// export const store = configureStore({
//     reducer: reducer,
// })
import { configureStore } from '@reduxjs/toolkit'
import authReducer from 'src/faceDetection/authSlice'
import facenetReducer from 'src/faceDetection/facenetSlice'
import userReducer from 'src/faceDetection/userSlice'
import matchReducer from 'src/faceDetection/matchSlice'
import detectReducer from 'src/faceDetection/detectSlice'
import similarityReducer from 'src/faceDetection/similaritySlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    facenet: facenetReducer,
    user: userReducer,
    match: matchReducer,
    detect: detectReducer,
    similarity: similarityReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})
