// ** MUI Imports
import Fab from '@mui/material/Fab'
import AppBar from '@mui/material/AppBar'
import { styled } from '@mui/material/styles'
import MuiToolbar from '@mui/material/Toolbar'

// ** Icons Imports
import ArrowUp from 'mdi-material-ui/ArrowUp'
import Logo from 'src/assets/images/Logo.png'

// ** Theme Config Import
import themeConfig from 'src/configs/themeConfig'

// ** Components
import Customizer from 'src/@core/components/customizer'
import Footer from './components/shared-components/footer'
import Navigation from './components/horizontal/navigation'
import ScrollToTop from 'src/@core/components/scroll-to-top'
import AppBarContent from './components/horizontal/app-bar-content'

// ** Util Import
import { hexToRGBA } from 'src/@core/utils/hex-to-rgba'

// ** Styled Component
import DatePickerWrapper from 'src/@core/styles/libs/react-datepicker'
import { useAuth } from 'src/hooks/useAuth'
import { MEDIA_URL } from 'src/network/endpoints'
// import Avatar from '../components/mui/avatar'
import { Avatar, Typography, Box } from '@mui/material'
import Location from 'src/assets/images/location.svg'
import { Link } from 'react-router-dom'
import NotificationDropdown from './components/shared-components/NotificationDropdown'
import { useGeolocated } from 'react-geolocated'
import { useEffect, useState } from 'react'
import axios from 'axios'

const HorizontalLayoutWrapper = styled('div')({
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  ...(themeConfig.horizontalMenuAnimation && { overflow: 'clip' })
})

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  width: '100%',
  padding: `${theme.spacing(0, 6)} !important`,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(4)
  },
  [theme.breakpoints.down('xs')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}))

const ContentWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  transition: 'padding .25s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}))

const HorizontalLayout = props => {
  // ** Props
  const {
    hidden,
    children,
    settings,
    scrollToTop,
    saveSettings,
    horizontalNavMenuContent: userHorizontalNavMenuContent
  } = props

  const { user } = useAuth()
  console.log('user', user)

  // ** Vars
  const { skin, appBar, navHidden, appBarBlur, contentWidth } = settings
  const [currentCity, setCurrentCity] = useState('')

  const fetchCurrentLocation = async (latitude, longitude) => {
    // const coords = localStorage.getItem('coords')
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords?.latitude},${coords?.longitude}&key=AIzaSyCz5jE0upqgbV9IlAt7R8asbRxtC7HBoew`
      )
      console.log(
        'setCurrentCity',
        response.data.results[0].address_components.find(component => {
          return component.types.includes('locality')
        })
      )
      setCurrentCity(
        response.data.results[0].address_components.find(component => {
          return component.types.includes('locality')
        })
      )
    } catch (error) {
      // Loader.hide()
      console.error('Error fetching nearby airports:', error)
    }
  }

  const { coords, getPosition, isGeolocationAvailable, isGeolocationEnabled, positionError } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false
    },
    userDecisionTimeout: 5000,
    watchLocationPermissionChange: true
  })
  // console.log('coordscoords', coords)

  useEffect(() => {
    if (coords) {
      fetchCurrentLocation()
    }
  }, [coords])

  return (
    <HorizontalLayoutWrapper className='layout-wrapper'>
      <AppBar
        color='default'
        elevation={skin === 'bordered' ? 0 : 3}
        className='layout-navbar-and-nav-container'
        position={appBar === 'fixed' ? 'sticky' : 'static'}
        sx={{
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          ...(appBar === 'static' && { zIndex: 13 }),
          backgroundColor: theme => theme.palette.background.paper,
          ...(skin === 'bordered' && { borderBottom: theme => `1px solid ${theme.palette.divider}` }),
          transition: 'border-bottom 0.2s ease-in-out, backdrop-filter .25s ease-in-out, box-shadow .25s ease-in-out',
          ...(appBar === 'fixed'
            ? appBarBlur && {
                backdropFilter: 'blur(8px)',
                backgroundColor: theme => hexToRGBA(theme.palette.background.paper, 0.85)
              }
            : {})
        }}
      >
        {/* <Box
          className='layout-navbar'
          sx={{
            width: '100%',
            ...(navHidden ? {} : { borderBottom: theme => `1px solid ${theme.palette.divider}` })
          }}
        >
          <Toolbar
            className='navbar-content-container'
            sx={{
              mx: 'auto',
              ...(contentWidth === 'boxed' && { '@media (min-width:1440px)': { maxWidth: 1440 } }),
              minHeight: theme => `${theme.mixins.toolbar.minHeight - 1}px !important`
            }}
          >
            <AppBarContent {...props} hidden={hidden} settings={settings} saveSettings={saveSettings} />
          </Toolbar>
        </Box> */}

        {navHidden ? null : (
          <>
            <Box
              className='layout-horizontal-nav'
              display='flex'
              alignItems='center'
              justifyContent='center'
              sx={{ width: '100%', height: '90px', bgcolor: '#0C7362' }}
              gap={4}
            >
              <Link to='/home'>
                <img src={Logo} alt='logo' style={{ height: '90px', width: 'auto', paddingLeft: '113px' }} />
              </Link>
              <Toolbar
                className='horizontal-nav-content-container'
                sx={{
                  bgcolor: 'primary',
                  mx: 'auto',
                  height: '100%',
                  ...(contentWidth === 'boxed' && { '@media (min-width:1440px)': { maxWidth: 1440 } }),
                  minHeight: theme => `${theme.mixins.toolbar.minHeight - (skin === 'bordered' ? 1 : 0)}px !important`
                }}
              >
                {(userHorizontalNavMenuContent && userHorizontalNavMenuContent(props)) || <Navigation {...props} />}
              </Toolbar>
              {/* <AppBarContent {...props} hidden={hidden} settings={settings} saveSettings={saveSettings} /> */}
              <NotificationDropdown settings={settings} />
              <Box display='flex' flexDirection='row' alignItems='center' gap={3} pr='113px'>
                <Avatar
                  alt={user.full_name}
                  sx={{ height: '50px', width: '50px' }}
                  src={`${MEDIA_URL}${user.profile_picture}`}
                />
                <Box display='flex' flexDirection='column' minWidth='fit-content'>
                  <Typography variant='fm-p1' color='common.white' fontWeight={600}>
                    {user.full_name}
                  </Typography>
                  {user.isBusinessAccount && (
                    <Typography variant='fm-p4' color='common.white' fontWeight={400}>
                      Business Account
                    </Typography>
                  )}

                  <Box display='flex' flexDirection='row' alignItems='center' gap={1} minWidth='fit-content'>
                    <img src={Location} alt='location' style={{ height: '15px', width: 'auto' }} />
                    <Typography variant='fm-p3' color='common.white' fontWeight={400} sx={{ textWrap: 'nowrap' }}>
                      {currentCity?.long_name || 'Your city'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </AppBar>

      <ContentWrapper
        className='layout-page-content'
        sx={{
          ...(contentWidth === 'boxed' && {
            mx: 'auto',
            '@media (min-width:1440px)': { maxWidth: 1440 },
            '@media (min-width:1200px)': { maxWidth: '100%' }
          })
        }}
      >
        {children}
      </ContentWrapper>

      <Footer {...props} />

      <DatePickerWrapper sx={{ zIndex: 11 }}>
        <Box id='react-datepicker-portal'></Box>
      </DatePickerWrapper>

      {themeConfig.disableCustomizer || hidden ? null : <Customizer />}

      {scrollToTop ? (
        scrollToTop(props)
      ) : (
        <ScrollToTop className='mui-fixed'>
          <Fab color='primary' size='small' aria-label='scroll back to top'>
            <ArrowUp />
          </Fab>
        </ScrollToTop>
      )}
    </HorizontalLayoutWrapper>
  )
}

export default HorizontalLayout
