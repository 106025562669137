// export const API_BASE_URL = 'http://192.168.29.54:3000'
export const API_BASE_URL = 'https://api.goparcel247.com'
export const MEDIA_URL = 'https://d1hj3r7039erwq.cloudfront.net'
export const GOOGLE_API_KEY = 'AIzaSyBydxmR64ODpWEtho3aPfVb-f6yvSy7uxY'

export const ApiEndpoints = {
  AUTH: {
    register: `${API_BASE_URL}/api/v1/auth/register/customer`,
    login: `${API_BASE_URL}/api/v1/auth/login/customer`,
    me: `${API_BASE_URL}/api/v1/auth/me`,
    create_mpin: `${API_BASE_URL}/api/v1/auth/create/mpin`,
    check_mpin: `${API_BASE_URL}/api/v1/auth/check/mpin`,
    forgot_mpin: `${API_BASE_URL}/api/v1/auth/forgot/mpin`,
    reset_mpin: `${API_BASE_URL}/api/v1/auth/reset/mpin`,
    delete_user: `${API_BASE_URL}/api/v1/auth`,
    check_referral_code: `${API_BASE_URL}/api/v1/auth/check/referal_code/customer`
  },
  SOCIAL_AUTH: {
    google_login: `${API_BASE_URL}/api/v1/social/auth/google/login/customer`,
    google_signup: `${API_BASE_URL}/api/v1/social/auth/google/register/customer`
  },
  OTP: {
    verifyotp: type => `${API_BASE_URL}/api/v1/auth/verify/otp/${type}`,
    resendotp: type => `${API_BASE_URL}/api/v1/auth/resend/otp/${type}`
  },
  PROFILE: {
    update_profile: `${API_BASE_URL}/api/v1/auth`
  },
  LEGAL_CONTENT: {
    privacy_policy: `${API_BASE_URL}/api/v1/misc/legalcontent/privacy_policy`,
    terms_and_condition: `${API_BASE_URL}/api/v1/misc/legalcontent/terms_and_conditions`
  },
  ORDER: {
    order: `${API_BASE_URL}/api/v1/orders`,
    get_order: id => `${API_BASE_URL}/api/v1/orders/${id}`
  },
  ORDER_DATA: {
    hub_address: `${API_BASE_URL}/api/v1/misc/hubaddress`,
    vehicle_type: `${API_BASE_URL}/api/v1/customer/check/vehicleType`,
    size_chart: `${API_BASE_URL}/api/v1/misc/sizecharts`,
    vehicle_list: `${API_BASE_URL}/api/v1/misc/vehicle/types`,
    dropoff_locations: `${API_BASE_URL}/api/v1/misc/drop_locations`
  },
  CATEGORIES: {
    categories: type => `${API_BASE_URL}/api/v1/misc/categories/${type}`,
    product_category: type => `${API_BASE_URL}/api/v1/misc//categories/product/${type}`
  },
  BUSINESS_ACCOUNT: {
    business_account: `${API_BASE_URL}/api/v1/business`
  },
  SEARCH_DRIVER: {
    search_driver: order_id => `${API_BASE_URL}/api/v1/customer/search/drivers/${order_id}`
  },
  REFER_FRIEND: {
    refer_friend_info: `${API_BASE_URL}/api/v1/misc/refer_friends/customer`
  },
  NOTIFICATION: {
    notification: `${API_BASE_URL}/api/v1/notifications`
  },
  WALLET: {
    view_wallet: `${API_BASE_URL}/api/v1/wallets`,
    search_user: `${API_BASE_URL}/api/v1/wallets/select_user/customer`,
    payment: order_id => `${API_BASE_URL}/api/v1/wallets/payment/${order_id}`,
    transfer_funds: user_id => `${API_BASE_URL}/api/v1/wallets/transfer/${user_id}`
  },
  FEEDBACK: {
    feedbacks: `${API_BASE_URL}/api/v1/misc/feedbacks`
  },
  COUPON: {
    get_coupons: `${API_BASE_URL}/api/v1/misc/coupons`,
    redeem_coupons: order_id => `${API_BASE_URL}/api/v1/customer/coupons/redeem/${order_id}`
  },
  RESCHEDULE: {
    reschedule_order: order_id => `${API_BASE_URL}/api/v1/customer/reschedule/order/${order_id}`
  }
}
