import { useEffect, useState } from 'react'
import { io } from 'socket.io-client'
import { API_BASE_URL } from './network/endpoints'

// const SERVER_URL = 'http://192.168.29.54:3000'
const SERVER_URL = 'https://api.goparcel247.com'

const useSocket = (namespace, orderId) => {
  const [socket, setSocket] = useState(null)
  const [message, setMessage] = useState(null)

  useEffect(() => {
    const newSocket = io(`${SERVER_URL}/${namespace}`, {
      path: '/findDrivers/',
      transports: ['websocket'],
      reconnectionAttempts: 3,
      timeout: 20000
    })
    // setSocket(newSocket)
    console.log('newSocket', newSocket)
    newSocket.on(orderId, data => {
      setMessage(data)
      console.log('socketData', data)
    })

    return () => {
      newSocket.disconnect()
    }
  }, [namespace, orderId])

  return message
}

export default useSocket
