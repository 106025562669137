import React, { Suspense, useEffect } from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import FallbackSpinner from './@core/components/spinner'

import AuthGuard from 'src/@core/components/auth/AuthGuard'
import UserLayout from './layouts/UserLayout'
import BlankLayout from './@core/layouts/BlankLayout'
// import BlankLayoutWithAppBar from './@core/layouts/BlankLayoutWithAppBar'
import AclGuard from './@core/components/auth/AclGuard'
import GuestGuard from './@core/components/auth/GuestGuard'
import { defaultACLObj } from './configs/acl'
import AuthLayout from './layouts/AuthLayout'
import ChangeLanguage from './pages/changeLanguage'
import { toast, ToastContainer } from 'react-toastify'
import socket from 'src/socket'
import Message from './views/notifications/Message'
import 'react-toastify/dist/ReactToastify.css'

const HomePage = React.lazy(() => import('./pages/home'))
const LoginPage = React.lazy(() => import('./pages/login'))
const SignUpPage = React.lazy(() => import('./pages/signUp'))
const VerifyOtpPage = React.lazy(() => import('./pages/verifyOtp'))
const ProfilePage = React.lazy(() => import('./pages/profile'))
const OrderPage = React.lazy(() => import('./pages/order'))
const CreateOrderPage = React.lazy(() => import('./pages/createOrder'))
const PaymentPage = React.lazy(() => import('./pages/payment'))
const OrderDetailsPage = React.lazy(() => import('./pages/orderDetails'))
const ShowQRPage = React.lazy(() => import('./pages/showQR'))
const OrderOtpPage = React.lazy(() => import('./pages/orderOtp'))
const PayNowPage = React.lazy(() => import('./pages/payNow'))
const CreateMpinPage = React.lazy(() => import('./pages/createMpin'))
const CheckMpinPage = React.lazy(() => import('./pages/checkMpin'))
const BusinessAccountPage = React.lazy(() => import('./pages/businessAccount'))
const PaymentMethodPage = React.lazy(() => import('./pages/paymentMethod'))
const PaymentCompletedPage = React.lazy(() => import('./pages/paymentCompleted'))
const FeedbackPage = React.lazy(() => import('./pages/feedback'))
const SelectBusinessPlanPage = React.lazy(() => import('./pages/selectBusinessPlan'))
const Page404 = React.lazy(() => import('./pages/404'))
const DefaultPage = React.lazy(() => import('./pages/default'))
const PickupFromCenterPage = React.lazy(() => import('./pages/pickupFromCenter'))

// const Camera = React.lazy(() => import('./pages/Camera'))

const Page401 = React.lazy(() => import('./pages/401'))

// const Message = ({ notification }) => {
//   return (
//     <div>
//       <h4>{notification.title}</h4>
//       <p>{notification.body}</p>
//     </div>
//   )
// }

const Guard = ({ children, authGuard, guestGuard }) => {
  if (guestGuard) {
    return <GuestGuard>{children}</GuestGuard>
  } else if (!guestGuard && !authGuard) {
    return <>{children}</>
  } else {
    return <AuthGuard>{children}</AuthGuard>
  }
}

function App() {
  const aclAbilities = defaultACLObj
  // const { VAPID_KEY } = 'BFeBvxA1uiPGPQHfarxpKYIRZ-LaYA6S8d83Y2sC4oeudLGHuZC5XLmF7_yBW_MjuDOE6vDcVXCiZNhaLxcW15s'

  // async function requestPermission() {
  //   const permission = await Notification.requestPermission()
  //   if (permission === 'granted') {
  //     const token = await getToken(messaging, {
  //       vapidKey: VAPID_KEY
  //     })
  //       .then(currentToken => {
  //         if (currentToken) {
  //           console.log('currentToken', currentToken)
  //           localStorage.setItem('FCMToken', currentToken)
  //         } else {
  //           // Show permission request UI
  //           console.log('No registration token available. Request permission to generate one.')
  //           // ...
  //         }
  //       })
  //       .catch(err => {
  //         console.log('An error occurred while retrieving token. ', err)
  //         // ...
  //       })
  //     console.log('token', token)
  //   } else if (permission === 'denied') {
  //     console.log('You denied for the notification')
  //   }
  // }
  useEffect(() => {
    try {
      // requestPermission()
      // Connect to the server
      socket.connect()

      // Handle connection event
      socket.on('connect', () => {
        console.log('Connected to server with custom path')
      })

      // Handle disconnection event
      socket.on('disconnect', () => {
        console.log('Disconnected from server')
      })

      // Clean up on unmount
      return () => {
        socket.off('connect')
        socket.off('disconnect')
        socket.disconnect()
      }
    } catch (error) {
      console.log('error in App.js', error)
    }
  }, [])

  // onMessage(messaging, payload => {
  //   toast(<Message notification={payload?.notification} />)
  // })
  // if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then(registration => {
      console.log('Service Worker registered with scope:', registration.scope)
      // messaging.useServiceWorker(registration)
    })
    .catch(error => {
      console.error('Service Worker registration failed:', error)
    })
  // }

  return (
    <Suspense fallback={<FallbackSpinner />}>
      <AclGuard aclAbilities={aclAbilities}>
        <Routes>
          <Route
            element={
              <BlankLayout>
                <Outlet />
              </BlankLayout>
            }
          >
            <Route path='/' element={<DefaultPage />} />
            <Route path='/401' element={<Page401 />} />
            <Route path='/404' element={<Page404 />} />

            <Route
              element={
                <AuthLayout>
                  <Outlet />
                </AuthLayout>
              }
            >
              <Route
                element={
                  <Guard guestGuard>
                    <Outlet />
                  </Guard>
                }
              >
                <Route path='' element={<DefaultPage />}></Route>
                <Route path='/login' element={<LoginPage />}></Route>
                <Route path='/signup' element={<SignUpPage />}></Route>
                <Route path='/verify-otp' element={<VerifyOtpPage />}></Route>
                <Route path='/create-mpin' element={<CreateMpinPage />}></Route>
                <Route path='/check-mpin' element={<CheckMpinPage />} />
              </Route>
            </Route>
          </Route>

          <Route path='/' element={<Navigate to='/login' replace />} />

          <Route
            element={
              <UserLayout>
                <Outlet />
              </UserLayout>
            }
          >
            <Route
              element={
                <Guard authGuard>
                  <Outlet />
                </Guard>
              }
            >
              <Route path='home' element={<HomePage />} />
              <Route path='order' element={<OrderPage />} />
              <Route path='profile' element={<ProfilePage />} />
              <Route path='order/create' element={<CreateOrderPage />} />
              <Route path='/changeLanguage' element={<ChangeLanguage />} />
              <Route path='order/payment/:id' element={<PaymentPage />} />
              <Route path='order/order-details/:id' element={<OrderDetailsPage />} />
              <Route path='order/show-qr/:id' element={<ShowQRPage />} />
              <Route path='/order/otp/:id' element={<OrderOtpPage />} />
              <Route path='/order/pay-now/:id' element={<PayNowPage />} />
              <Route path='/profile/business-account' element={<BusinessAccountPage />} />
              <Route path='order/payment-method/:id' element={<PaymentMethodPage />} />
              <Route path='order/payment-completed/:id' element={<PaymentCompletedPage />} />
              <Route path='profile/select-business-plan' element={<SelectBusinessPlanPage />} />
              <Route path='order/feedback/:id' element={<FeedbackPage />} />
              <Route path='order/pickup/:id' element={<PickupFromCenterPage />} />
              {/* <Route path='order/camera' element={<Camera />} /> */}
            </Route>
          </Route>

          {/* If no route found redirect it to --> /404 */}
          <Route path='*' element={<Navigate to='/404' replace />} />
        </Routes>
      </AclGuard>
      <ToastContainer />
    </Suspense>
  )
}

export default App
